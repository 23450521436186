import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import Downshift from 'downshift'
import { ItemListRes, ItemListTodo } from '../../../redux/slices/store/material-issue/ItemList'
import Loader from '../../../component/loader/Loader'
import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar'
import SaveItems from './SaveItems'
import { LuPrinter } from 'react-icons/lu';
import Swal from 'sweetalert2'
import { LuFileEdit, LuTrash2 } from 'react-icons/lu'
import { Button, Table } from 'react-bootstrap'
import EditSaveItems from './EditSaveItems'
import { AddMaterialIssueRes, AddMaterialIssueTodo } from '../../../redux/slices/store/material-issue/AddMaterialIssue'
import { useNavigate } from 'react-router-dom'
import MaterialIssuetPdf from './MaterialIssuePdf'

export default function AddMaterialIssue() {

    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const [saveItems, setSaveItems] = useState(false)
    const [inputValue, setInputValue] = useState('')
    const [itemsData, setItemsData] = useState('')
    const [editItems, setEditItems] = useState(false)
    const [indexValue, setIndexValue] = useState('')
    const [orderItems, setOrderItems] = useState([])
    const [items, setItems] = useState([])
    const [pdfModal, setPdfModal] = useState(false)

    console.log("orderItems",orderItems)

    const handlePdf = () => {
        setPdfModal(true)
    }
    const navigate = useNavigate()

    const handleItemSave = (data) => {
        setSaveItems(true)
        setItemsData(data)
    }

    const handleItemEdit = (data) => {
        setIndexValue(data)
        setEditItems(true)
    }

    const itemDataList = (data) => {
        dispatch(ItemListTodo(data)).then(() => itemListFun())
    }

    const itemListFun = () => {
        if (ItemListRes && ItemListRes.success) {
            setItems(ItemListRes.data)
            setLoading(false)
        } else {
            setLoading(false)
        }
    }
    const delete_items = (index) => {
        setOrderItems(oldValues => {
            return oldValues.filter((_, i) => i !== index)
        })
    }

    const AddOrderItem = () => {
        dispatch(AddMaterialIssueTodo(orderItems)).then(() => addOrderFun())
    }

    const addOrderFun = () => {
        if (AddMaterialIssueRes && AddMaterialIssueRes.success) {
            Swal.fire({
                position: "center",
                icon: "success",
                title: "Order Add Successfull....!",
                showConfirmButton: false,
                timer: 1200
            });
            setTimeout(() => {
                handlePdf()
            }, 500);
            // setOrderItems('')
            
        } else {

            Swal.fire({
                title: "Order",
                text: AddMaterialIssueRes && AddMaterialIssueRes.message,
                icon: "error"
            });
        }
    }

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className='layout_head'>
                            <h5> Add Material</h5>
                        </div>
                        <Downshift
                            onChange={(selectedItem, { reset }) => {
                                handleItemSave(selectedItem)
                                if (selectedItem) {
                                    setInputValue('');
                                    reset({ inputValue: '' });

                                }
                            }}
                            itemToString={(item) => (item ? item.vitem_name : '')}
                        >
                            {({
                                getToggleButtonProps,
                                getInputProps,
                                getItemProps,
                                getLabelProps,
                                getMenuProps,
                                isOpen,
                                inputValue: downshiftInputValue,
                                highlightedIndex,
                                selectedItem,
                                getRootProps,
                            }) => (
                                <div>
                                    <div className='d-flex align-items-center'>
                                        <lable {...getLabelProps()}>Search Items : </lable>
                                        <div className='ms-2' {...getRootProps({}, { suppressRefError: true })}>
                                            <input
                                                {...getInputProps({
                                                    onChange: (event) => { setInputValue(event.target.value); itemDataList(event.target.value); setLoading(true) },
                                                    value: inputValue,
                                                })}
                                                style={{ width: '500px' }}
                                                className='form-control form_input'
                                            />
                                        </div>
                                        {/* <div>
                                        <Button {...getToggleButtonProps({ onClick: () => { setPageIndex(pageIndex + 1); itemPageLimit(inputValue) } })} type='button' variant="" className='save_btn ms-3'>Load More</Button>
                                    </div> */}
                                    </div>

                                    <ul {...getMenuProps()} className={inputValue.length ? 'main-container' : ''}>
                                        {loading ?
                                            <div className='d-flex justify-content-center'>
                                                <Loader />
                                            </div> :

                                            <PerfectScrollbar>
                                                {isOpen && inputValue &&
                                                    items && items.filter((item) =>
                                                        !inputValue ||
                                                        Object.values(item).some(
                                                            (value) =>
                                                                typeof value === 'string' &&
                                                                value.toLowerCase().includes(downshiftInputValue.toLowerCase())
                                                        )
                                                    )

                                                        .map((item, index) => (
                                                            <div
                                                                key={item.vitem_code}
                                                                className='item-container'
                                                                style={{
                                                                    backgroundColor: highlightedIndex === index ? 'lightgray' : 'white',
                                                                }}
                                                                {...getItemProps({ item, index })}
                                                            >

                                                                <div className='items_data'>
                                                                    {/* <p>Item Code : <span>{item.vitem_code}</span></p> */}
                                                                    <p>SKF ITEM NAME : <span> {item.vitem_description} </span></p>
                                                                    <p>OPIL ITEM NAME : <span> {item.vitem_name} </span></p>
                                                                    <p>OPIL ITEM CODE : <span> {item.vdrawing_code} </span></p>

                                                                    <div className='d-flex align-items-center'>
                                                                        <p className=''>STOCK : <span> {item.mstock} </span></p>
                                                                        <p className='ms-5'>MAKE : <span> {item.vparent_group_name} </span></p>
                                                                        <p className='ms-5'>RECEIVE DATE : <span> {item?.vreceived_date} </span></p>

                                                                    </div>

                                                                </div>
                                                            </div>
                                                        ))
                                                }
                                            </PerfectScrollbar>}
                                    </ul>
                                </div>
                            )}
                        </Downshift>
                        <div className='mt-3'>
                            {/* <div className='d-flex justify-content-end'>
                                <Button variant='primary' size='sm' onClick={handlePdf} >
                                    Print <LuPrinter />
                                </Button>
                            
                            </div> */}
                            <Table striped bordered hover >
                                <thead >
                                    <tr >
                                        <th style={{ width: "20px" }}>S.NO</th>
                                        <th>SKF ITEM NAME</th>
                                        <th>OPIL ITEM NAME</th>
                                        <th>OPIL ITEM CODE</th>
                                        <th>MAKE</th>
                                        <th>STOCK</th>
                                        <th>QUANTITY</th>
                                        <th>RECEIVE DATE</th>
                                        <th>ACTION</th>
                                    </tr>
                                </thead>
                                {
                                    orderItems && orderItems.length ?
                                        <tbody>
                                            {orderItems && orderItems.map((value, index) => {
                                                return (
                                                    <>
                                                        <tr>
                                                            <td>{index + 1}</td>
                                                            <td>{value.vitem_description}</td>
                                                            <td>{value.vitem_name}</td>
                                                            <td>{value.vdrawing_code}</td>
                                                            <td>{value.vparent_group_name}</td>
                                                            <td>{value.mstock}</td>
                                                            <td>{value && value.values && value.values.quantity}</td>
                                                            <td>{value?.vreceived_date}</td>
                                                            <td style={{ width: "100px" }}>
                                                                <span className='d-flex '>
                                                                    <LuFileEdit onClick={() => handleItemEdit(index)} size={20} className='edit text-success ms-3' />
                                                                    <LuTrash2 onClick={() => delete_items(index)} size={20} className='delete text-danger ms-3' />
                                                                </span>
                                                            </td>
                                                        </tr></>
                                                )
                                            })}
                                        </tbody> :
                                        <tbody>
                                            <tr>
                                                <td className='text-center p-5' style={{ fontSize: "18px", color: '#9d9d9d', fontWeight: "500" }} colSpan={10}>Please Select Items</td>
                                            </tr>
                                        </tbody>
                                }
                            </Table>
                            {orderItems && orderItems.length ? <div className='d-flex justify-content-end'>
                                <Button onClick={() => AddOrderItem()} type='button' variant="" className='save_btn'>CONFIRM ORDER</Button>
                            </div> : ''}
                        </div>
                    </div>
                </div>
            </div>
            <SaveItems saveItems={saveItems} setSaveItems={setSaveItems} itemsData={itemsData} orderItems={orderItems} setOrderItems={setOrderItems} />
            <EditSaveItems editItems={editItems} setEditItems={setEditItems} orderItems={orderItems} setOrderItems={setOrderItems} indexValue={indexValue} />
            <MaterialIssuetPdf pdfModal={pdfModal} setPdfModal={setPdfModal} orderItems={orderItems} setOrderItems={setOrderItems} />
        </>
    )
}
