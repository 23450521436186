import React from 'react';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import Navbar from './layout/Navbar';
import Swal from 'sweetalert2'

const useAuth = () => {
    let user =  localStorage.getItem('vcustomer_code') ;
    // console.log(user)
    if (user) {
        try {
            if ((user)) {
                return user;
            }
        } catch (error) {
            console.log("log1", error)
            return false
        }

    } else {
        console.log("log2", "test")
        return false
    }
}

const ProtectedRoutes = () => {
    const auth = useAuth()
    const location = useLocation()
    const navigate = useNavigate()
    // const userType = localStorage.getItem('type');

    // const object1 = {  
    //     "id": 3,
    //     "ilogin_detail_id": 1,
    //     "vurl_name": "Dashboard",
    //     "vurl_path": "/dashboard"
    //    }

    //    const object2 = {  
    //     "id": 4,
    //     "ilogin_detail_id": 2,
    //     "vurl_name": "Receive Material Details",
    //     "vurl_path": "/receive-material-details"
    // };



    var permission = JSON.parse(localStorage.getItem('permissionList'))

    // permission?.push(object1,object2);
    // console.log(permission)

    const isRouteAuthorized = permission && permission.some(
        (item) => item.vurl_path === location.pathname

    );
    if (!auth ) {
        return <Navigate to="/login" />;
    }
    // else if (!isRouteAuthorized) {
    //     Swal.fire({
    //         title: 'Access Denied',
    //         text: "You do not have permission to access this page. Please contact your Administrator to request access.",
    //         icon: 'error',
    //         showCancelButton: false,
    //         confirmButtonColor: '#121136',
    //         cancelButtonColor: '#EF3D50',
    //         confirmButtonText: 'Ok'
    //     }).then((result) => {
    //         if (result.isConfirmed) {
    //             navigate(-1)
    //         }
    //     })
    //     return null;
    // }
    else {
        return (
            <div>
                <Navbar />
                <div id="content">
                    <Outlet />
                </div>
            </div>
        )
    }
}


export default ProtectedRoutes;
