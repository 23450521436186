import React from 'react'
import { MdCancel } from "react-icons/md";
import { Form } from 'react-bootstrap'
import { useFormik } from 'formik'
import { ToastContainer, toast } from 'react-toastify';
import Swal from 'sweetalert2'
import SaveButton from '../../../component/buttons/SaveButton';
import * as Yup from "yup";

export default function SaveItems(props) {
    const { saveItems, setSaveItems, itemsData, orderItems, setOrderItems } = props;
    const initialValues = {
        quantity: '',
        remark: '',
    }
    console.log(itemsData)

    const AddItemSchema = Yup.object({
        // quantity: Yup.number().min(1, "Quantity Can't be less then 1").required('Quantity is required'),
        quantity: Yup.number()
        .min(1, "Quantity can't be less than 1")
        .required('Quantity is required')
        .test('is-in-stock', 'Quantity is more than stock', function(value) {
          return value <= itemsData?.mstock;
        })
    })
    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues: initialValues,
        validationSchema: AddItemSchema,

        onSubmit: (values, action) => {
            var arr = false;
            for (var i = 0, len = orderItems.length; i < len; i++) {
                if (orderItems[i].vitem_code === itemsData.vitem_code) {
                    arr = true
                }
            }
            if (!arr) {
                // toast.success("Item Add Successfull....!", { position: "bottom-right" })
                setOrderItems((prev) => [...prev, { ...itemsData,
                     values, 
                     iware_house_id: localStorage.getItem('iware_house_id'),
                     vcustomer_code:localStorage.getItem('vcustomer_code'),
                     ibusiness_area_id:localStorage.getItem('ibusiness_area_id') 
                    }])
                Swal.fire({
                    position: "center",
                    icon: "success",
                    title: "Item Add Successfull....!",
                    showConfirmButton: false,
                    timer: 800
                });
            } else {
                // toast.warning("Item Already Exists...! ", { position: "bottom-right" })
                // alert('Item Already Exists...!')
                Swal.fire({
                    title: "Items",
                    text: 'Item Already Exists...!',
                    icon: "warning"
                });
            }
            action.resetForm()

            setTimeout(() => {
                setSaveItems(false)
            }, 500);
        },
    })
    return (
        <>
            <div className={saveItems ? 'modal fade show d-block' : 'modal fade show d-none'} style={{ background: 'rgba(0,0,0,0.8)' }}>
                <div className="modal-dialog modal-dialog-centered modal-lg  ">
                    <div className="modal-content pb-5" >
                        <div className="modal-header  d-flex justify-content-between"  >
                            <h5 className='text-center ' >Add Items </h5>
                            <span onClick={() => { setSaveItems(false); }}><MdCancel style={{ color: 'red' }} size={25} className="mb-2" /></span>
                        </div>
                        <div className='modal-body  p-4 '>
                            <div className='item_details '>
                                <div className=''>
                                    {/* <p> Code : <span>{itemsData && itemsData.vitem_code}</span></p> */}
                                    <p >SKF ITEM NAME: <span> {itemsData && itemsData.vitem_description} </span></p>
                                </div>
                                <p>OPIL ITEM NAME : <span> {itemsData && itemsData.vitem_name} </span></p>
                                <p >OPIL ITEM CODE : <span> {itemsData && itemsData.vdrawing_code} </span></p>


                                <div className='d-flex'>
                                    <p >STOCK : <span> {itemsData && itemsData.mstock} </span></p>
                                    <p className='ms-5'>MAKE : <span> {itemsData?.vparent_group_name} </span></p>
                                    <p className='ms-5'>RECEIVE DATE : <span> {itemsData?.vreceived_date}</span></p>

                                </div>
                            </div>
                            <div className='mt-3'>
                                <Form onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <Form.Group className="mb-2" controlId="text">
                                                <Form.Label className='form_label'>Quantity</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    className='form_input'
                                                    name='quantity'
                                                    value={values.quantity}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                                {errors.quantity && touched.quantity ? (<span className='text-danger ' style={{ fontSize: "15px" }} >{errors.quantity}</span>) : null}

                                            </Form.Group>
                                        </div>
                                        <div className="col-md-8">
                                            <Form.Group className="mb-2" controlId="text">
                                                <Form.Label className='form_label'>Remark</Form.Label>
                                                <Form.Control
                                                    type="textarea"
                                                    className='form_input'
                                                    name='remark'
                                                    value={values.remark}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />

                                            </Form.Group>
                                        </div>
                                        <div className='d-flex justify-content-end'>
                                            <SaveButton name={'Save'} />
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </>
    )
}
