import { configureStore } from "@reduxjs/toolkit";
import ReceiveMaterialList from "./slices/store/receive-material/ReceiveMaterialList";
import ReceiveMaterialDetails from "./slices/store/receive-material/ReceiveMaterialDetails";
import StockList from "./slices/store/stock-list/StockList";
import ItemList from "./slices/store/material-issue/ItemList";
import MaterialIssueList from "./slices/store/material-issue/MaterialIssueList";
import dashboard from "./slices/DashboardSlice/dashboard";
import ReceiveItemList from "./slices/store/receiveitem/ReceiveItemList";
import ReceiveItemDetails from "./slices/store/receiveitem/ReceiveItemDetails";
import StockReportList from "./slices/store/report-list/StockReportList";
import ReceiveReportList from "./slices/store/report-list/ReceiveReportList";
import IssueReportList from "./slices/store/report-list/IssueReportList";

const Store = configureStore({
    reducer: {
      Dashboard: dashboard,
      ReceiveMaterial: ReceiveMaterialList,
      ReceiveMaterialDetail: ReceiveMaterialDetails,
      ReceiveItemList : ReceiveItemList,
      ReceiveItemDetails:ReceiveItemDetails,
      StockList : StockList,
      ItemList: ItemList,
      MaterialIssueList: MaterialIssueList,
      StockReportList:StockReportList,
      ReceiveReportList:ReceiveReportList,
      IssueReportList:IssueReportList,
    }
})
export default Store;