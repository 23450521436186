import { useFormik } from 'formik';
import React, { useState } from 'react'
import { AiOutlineMail } from 'react-icons/ai'
import { RiLockPasswordLine } from 'react-icons/ri'
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import LoginButton from '../../buttons/LoginButton';
import { Form } from 'react-bootstrap';
import { logInRes, logInTodo } from '../../../redux/slices/LoginSlice/Login';
import { ToastContainer, toast } from 'react-toastify';
import { logInSchema } from './Validation';
import { FaUserCheck } from "react-icons/fa";

export default function LoginForm() {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const [spiner, setSpiner] = useState(false)
    const location = useLocation()

    const initiaValues = {
        username: '',
        password: '',
        vcustomer_code:'CPL/01/3433'
    }

    const { values, errors, touched, handleBlur, handleChange, handleSubmit } = useFormik({
        initialValues: initiaValues,
        validationSchema: logInSchema,

        onSubmit: (values, action) => {
            setSpiner(true)
            dispatch(logInTodo(values)).then(() => loginInFun())

        }
    })

    const loginInFun = () => {
        if (logInRes && logInRes.success) {
            // console.log(logInRes)
            localStorage.setItem('vcustomer_code', logInRes.data[0].vcustomer_code)
            localStorage.setItem('vcustomer_name', logInRes.data[0].vcustomer_name)
            localStorage.setItem('vcustomer_address', logInRes.data[0].vcustomer_address)
            localStorage.setItem('vcustomer_city', logInRes.data[0].vcustomer_city)
            localStorage.setItem('vcustomer_gst', logInRes.data[0].vcustomer_gst)
            localStorage.setItem('vcustomer_tax_type', logInRes.data[0].vcustomer_tax_type)
            localStorage.setItem('ibusiness_area_id', logInRes.data[0].ibusiness_area_id)
            localStorage.setItem('iware_house_id', logInRes.data[0].iware_house_id)
            localStorage.setItem('vuser_name', logInRes.data[0].vuser_name)
            localStorage.setItem('permissionList', JSON.stringify(logInRes.data[0].permissionList))

            setSpiner(false);
            navigate('/dashboard')
        } else {
            setSpiner(false);
            toast.error(logInRes && logInRes.message, { position: "bottom-right" });
        }
    }
    return (
        <>
            <div className='mt-3'>
                <Form onSubmit={handleSubmit}>

                    <div className="input-group  input_section" style={{ position: 'relative' }}>
                        <span className='input_icon'> <AiOutlineMail className='mb-1 me-2 ' style={{ color: "#105684" }} size={20} /></span>
                        <input type="text" className="form-control form-login-input login_placeholder "
                            placeholder="Username"
                            name='username'
                            value={values.username}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                    </div>
                    {errors.username && touched.username ? (<span className='text-danger text-start' >{errors.username}</span>) : null}


                    <div className="input-group mt-3 input_section" style={{ position: 'relative' }}>
                        <span className='input_icon'> <RiLockPasswordLine style={{ color: "#105684" }} className='mb-1 me-2' size={20} /></span>
                        <input type="password" className="form-control form-login-input "
                            placeholder="Password"
                            name='password'
                            value={values.password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                    </div>

                    {errors.password && touched.password ? (<span className='text-danger text-start' >{errors.password}</span>) : null}
                    <div className="input-group mt-3 input_section" style={{ position: 'relative' }}>
                        <span className='input_icon'> <FaUserCheck style={{ color: "#105684" }} className='mb-1 me-2' size={20} /></span>
                        <Form.Select className="form-control form-login-input " aria-label="Default select example"
                            placeholder="Select User"
                            name='vcustomer_code'
                            value={values.vcustomer_code}
                            onChange={handleChange}
                            onBlur={handleBlur}>
                           
                            <option value="CPL/01/3433">OPIL</option>
                            
                        </Form.Select>
                    </div>
                    <div className='mt-4 d-flex justify-content-center'>
                        <LoginButton name={'Login'} spiner={spiner} />
                    </div>
                </Form>
            </div>
            <ToastContainer />
        </>
    )
}
