import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap';
import { LuPrinter } from 'react-icons/lu';
import { useDispatch, useSelector } from 'react-redux';
import { useReactToPrint } from 'react-to-print'
import { ReceiveItemDetailsRes, ReceiveItemDetailsTodo } from '../../../redux/slices/store/receiveitem/ReceiveItemDetails';
import Loader from '../../../component/loader/Loader';

export default function PDFFile(props) {
    const { modal, setModal, bool, setBool, pdfData, setPdfData } = props;
    const handleClose = () => setModal(false);
    const [loading, setLoading] = useState(true)
    const handlePrint = useReactToPrint({
        content: () => document.getElementById('pdf-content'),
    });
    // const challan_code = pdfData?.vchallan_code
    // const item_code = pdfData?.vitem_code
    // console.log(challan_code)

    const dispatch = useDispatch()
    const pdfDetails = useSelector(state => state?.ReceiveItemDetails?.data?.data?.[0])

    // console.log(pdfDetails)

    useEffect(() => {
        if (modal) {
            setLoading(true);
        }
    }, [modal]);

    setTimeout(() => {
        setLoading(false)
    }, 1600)

    return (
        <>
            <Modal show={modal} onHide={handleClose} size="lg" aria-labelledby="example-modal-sizes-title-lg">
                <Modal.Header closeButton>
                    <Button variant='primary' size='sm' onClick={handlePrint}>
                        Print <LuPrinter />
                    </Button>
                </Modal.Header>
                <Modal.Body>
                    <div className="container  pt-5 mb-5 pb-5" id='pdf-content'>
                        {loading ?
                            <div className='d-flex justify-content-center pt-5 pb-5'>
                                <Loader />
                            </div> :
                            <div className="pdf_content_sec">
                                <h5>Material Tag</h5>
                                <div className="row mt-3 ">
                                    <div className="col-md-5 col-sm-5 mb-2">
                                        <div className='row align-items-center'>
                                            <div className="col-md-4 col-sm-5">
                                                <p>SL NO.</p>
                                            </div>
                                            <div className="col-md-8 col-sm-7">
                                                <span>{pdfDetails?.iseq_no}</span>
                                            </div>
                                            <div className="col-md-4 col-sm-5">
                                                <p>Head Type</p>
                                            </div>
                                            <div className="col-md-8 col-sm-7">
                                                <span>SKF Bearing</span>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-md-7 col-sm-7 mb-2">
                                        <div className='row align-items-center'>
                                            <div className="col-md-4 col-sm-4">
                                                <p>Receiving Date</p>
                                            </div>
                                            <div className="col-md-8 col-sm-8">
                                                <span>{pdfDetails?.vreceived_date}</span>
                                            </div>
                                            <div className="col-md-4 col-sm-4">
                                                <p>Required For</p>
                                            </div>
                                            <div className="col-md-8 col-sm-8">
                                                <span></span>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="col-md-5 col-sm-6">
                                        <div className='row align-items-center'>
                                            <div className="col-md-4 col-sm-4">
                                                <p>Dep. Name</p>
                                            </div>
                                            <div className="col-md-8 col-sm-8">
                                                <span className='ms-3'>OPIL VMI</span>
                                            </div>
                                            <div className="col-md-4 col-sm-4">
                                                <p>Mat. Doc.</p>
                                            </div>
                                            <div className="col-md-8 col-sm-8">
                                                <span className='ms-3'>{pdfDetails?.vchallan_code}</span>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-md-5 col-sm-6">
                                        <div className='row align-items-center'>
                                            <div className="col-md-5 col-sm-5">
                                                <p>Storage Bin</p>
                                            </div>
                                            <div className="col-md-7 col-sm-7">
                                                <span>{pdfDetails?.vbin_location}</span>
                                            </div>
                                            <div className="col-md-5 col-sm-5 mt-1">
                                                <p>Purchase Order</p>
                                            </div>
                                            <div className="col-md-7 col-sm-7 mt-1">
                                                <span></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 col-sm-12">
                                        <div className="row align-items-center">
                                            <div className="col-md-1 col-sm-1">
                                                <p>Vendor</p>
                                            </div>
                                            <div className="col-md-11 col-sm-11 ">
                                                <span className='ms-5 ps-2'>{pdfDetails?.vcompany_name}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 col-sm-12">
                                        <div className="row align-items-center">
                                            <div className="col-md-1 col-sm-1">
                                                <p>Material </p>
                                            </div>
                                            <div className="col-md-10 col-sm-10">
                                                <span className='ms-5 ps-2'>{pdfDetails?.vdrawing_code}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-5 col-sm-6">
                                        <div className="row align-items-center">
                                            <div className="col-md-6 col-sm-6">
                                                <p>RECEIVED QTY</p>
                                            </div>
                                            <div className="col-md-6 col-sm-6">
                                                <span>{pdfDetails?.fquantity}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-7 col-sm-6">
                                        <div className="row align-items-center">
                                            <div className="col-md-3 col-sm-4">
                                                <p>BATCH NO.</p>
                                            </div>
                                            <div className="col-md-9 col-sm-8">
                                                <span className='ms-4'></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-5 col-sm-6">
                                        <div className="row align-items-center">
                                            <div className="col-md-6 col-sm-6">
                                                <p>VALUATION TYPE</p>
                                            </div>
                                            <div className="col-md-6 col-sm-6">
                                                <span></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-7 col-sm-6">
                                        <div className="row align-items-center">
                                            <div className="col-md-3 col-sm-3">
                                                <p>SLED</p>
                                            </div>
                                            <div className="col-md-9 col-sm-9">
                                                <span className='ms-4'></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row align-items-center" style={{marginTop:'-7px'}}>
                                    <div className="col-md-2 col-sm-3">
                                        <p>Description :</p>
                                    </div>
                                    <div className="col-md-10 col-sm-9">
                                        <span className=''>{pdfDetails?.vitem_name}</span>
                                    </div>
                                    </div>
                                   
                                </div>
                            </div>
                        }

                    </div>

                </Modal.Body>
            </Modal>
        </>
    )
}
