import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ReceiveMaterialDetailsRes, ReceiveMaterialDetailsTodo } from '../../../redux/slices/store/receive-material/ReceiveMaterialDetails'
import { useLocation } from 'react-router-dom'
import DataTable from '../../../component/data_table/DataTable'
import Loader from '../../../component/loader/Loader'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import { Button, Form, Table } from 'react-bootstrap'
import { ApproveMaterialRes, ApproveMaterialTodo } from '../../../redux/slices/store/receive-material/ApproveReceiveMaterial'
import { toast, ToastContainer } from 'react-toastify'
import { useFormik, FieldArray, FormikProvider } from 'formik';
import { UpdateIMTRes, UpdateIMTTodo } from '../../../redux/slices/store/receive-material/UpdateImt'
import { UpdateQuantityRes, UpdateQuantityTodo } from '../../../redux/slices/store/receive-material/UpdatedQuantity'

export default function ReceiveMaterialDetails() {

    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const details = useSelector(state => state?.ReceiveMaterialDetail?.data?.data)
    const { state } = useLocation()
    const [bool, setBool] = useState(false)
    const navigate = useNavigate()

    useEffect(() => {
        setLoading(true)
        dispatch(ReceiveMaterialDetailsTodo(state?.vitm_code)).then(() => details_res())
    }, [bool])

    const details_res = () => {
        if (ReceiveMaterialDetailsRes?.success) {
            setLoading(false)
        } else {
            setLoading(false)
        }
    }
    const material_apv = () => {
        Swal.fire({
            title: "Approve Material ",
            text: "Are You Sure Want To Approve This Material ?",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#121136',
            cancelButtonColor: '#EF3D50',
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Approve'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(ApproveMaterialTodo(state?.vitm_code)).then(() => del_res())
            }

        })
    }
    const del_res = () => {
        if (ApproveMaterialRes?.success) {
            Swal.fire(
                "Material",
                'Material Approve Successfully',
                'success'
            )
            setTimeout(() => {
                navigate('/receive-item-list')
            }, 1500);

        } else {
            toast.error(ApproveMaterialRes?.message, { position: "bottom-right" })
        }
    }


    const cont_data = details?.map((value) => ({
        vitem_description: value?.vitem_description,
        vitem_code: value?.vitem_code,
        vitem_name: value?.vitem_name,
        vchallan_code: value?.vchallan_code,
        vdrawing_code: value?.vdrawing_code,
        vunit_short_name: value?.vunit_short_name,
        fquantity: value?.fquantity,
        receive_fquantity: value?.fquantity,
        vremarks: value?.vremarks,

    }))

    const initialValues = {
        data: cont_data
    }

    const formik = useFormik({
        initialValues: initialValues,
        enableReinitialize: true,

        onSubmit: (values, action) => {
            // console.log(values)
        },
    })

    const updateItem = (data) => {

        {
            data?.receive_fquantity === '' || data?.receive_fquantity === null ?
                Swal.fire(
                    "Items Update",
                    'Empty Filed is Not Allow',
                    'error'
                ) :
                dispatch(UpdateQuantityTodo(data)).then(() => update_res()) 
        }
    }

    const update_res = () => {
        if (UpdateQuantityRes?.success) {
            toast.success(UpdateQuantityRes?.message, { position: 'bottom-right' })
            setBool(!bool)
        } else {
            toast.error(UpdateQuantityRes?.message, { position: 'bottom-right' })
        }
    }


    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="layout_head">
                            <h5>Receive Material Details</h5>
                        </div>
                        {loading ?
                            <div className='d-flex juistify-content-center mt-5 '>
                                <Loader />
                            </div> :
                            details?.length ?
                                <>
                                    <div className='form_layout  material_detals mb-1'>
                                        <div className="row">
                                            <div className="col-md-3">
                                                <div className=''>
                                                    <p>IMT Code </p>
                                                    <span>{state?.vitm_code}</span>
                                                </div>
                                            </div>

                                            <div className="col-md-3">
                                                <div className=''>
                                                    <p>From Warehouse</p>
                                                    <span>{state?.vfrom_ware_house}</span>
                                                </div>
                                            </div>
                                            <div className="col-md-2">
                                                <div className=''>
                                                    <p>To Warehouse</p>
                                                    <span>{state?.vto_ware_house}</span>
                                                </div>
                                            </div>
                                            <div className="col-md-2">
                                                <div className=''>
                                                    <p>IMT Date</p>
                                                    <span>{state?.danx_date}</span>
                                                </div>
                                            </div>
                                            <div className="col-md-2 ">
                                                <div className=''>
                                                    <p>Send By</p>
                                                    <span>{state?.vsend_by}</span>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-md-12 mt-2">

                                        <FormikProvider value={formik}>
                                            <form onSubmit={formik.handleSubmit}>
                                                <Table striped bordered hover>
                                                    <thead>
                                                        <tr>
                                                            <th style={{ width: "20px" }}>S.NO</th>
                                                            <th>SKF ITEM NAME</th>
                                                            <th>OPIL ITEM NAME</th>
                                                            <th>OPIL ITEM CODE</th>
                                                            <th>UoM</th>
                                                            <th>QUANTITY</th>
                                                            <th>RECEIVE QTY.</th>
                                                            <th>REMARK</th>
                                                            <th>ACTION</th>
                                                        </tr>
                                                    </thead>
                                                    <FieldArray
                                                        name="data"
                                                        render={(arrayHelpers) => (
                                                            <tbody>
                                                                {formik && formik.values && formik.values.data && formik.values.data.map((value, index) => (
                                                                    <tr key={index}>
                                                                        <td>{index + 1}</td>
                                                                        <td>{value.vitem_description}</td>
                                                                        <td>{value?.vitem_name}</td>
                                                                        <td>{value?.vdrawing_code} </td>
                                                                        <td>{value.vunit_short_name}</td>
                                                                        <td>{value.fquantity}</td>
                                                                        <td>
                                                                            <Form.Control
                                                                                type="text"
                                                                                className='form_input'
                                                                                name={`data[${index}].receive_fquantity`}
                                                                                style={{ width: '120px' }}
                                                                                value={formik.values.data[index].receive_fquantity}
                                                                                onChange={formik.handleChange}
                                                                                onBlur={formik.handleBlur}
                                                                            />
                                                                        </td>
                                                                        <td>{value.vremarks}</td>
                                                                        <td style={{ width: '70px' }}>
                                                                            <Button className='btn update_btn' onClick={() => { updateItem(value) }} type="submit">UPDATE</Button>
                                                                        </td>
                                                                    </tr>))}
                                                            </tbody>
                                                        )}
                                                    />

                                                </Table>
                                            </form>
                                        </FormikProvider>
                                        <div className='d-flex justify-content-end mt-2'>
                                            <Button className='add_btn' onClick={() => material_apv()}>Approve</Button>

                                        </div>
                                    </div>

                                </>
                                :
                                <div className='empty_section'>
                                    <span>DATA NOT FOUND</span>
                                </div>
                        }
                    </div>
                </div>
            </div >
            <ToastContainer />
        </>
    )
}
