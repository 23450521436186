import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var AddMaterialIssueRes;
export const AddMaterialIssueTodo = createAsyncThunk('AddMaterialIssue', async (data) => {
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'add_item_confirmed',
            data: {data },
            headers: { "Content-Type": "application/json" }
        })
        return AddMaterialIssueRes = res.data

    } catch (error) {
        return AddMaterialIssueRes = error.response.data
    }
})