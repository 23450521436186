import React from 'react'
import Logo from '../../assest/images/ABSPL_logo.png'
import LoginForm from '../../component/form/login/LoginForm'

export default function Login() {
    return (
        <>
           <div className='login_bg'>
                <div className="container h-100">
                    <div className="login-container row d-flexh-100 g-0 justify-content-center" >

                        <div className="col-md-5  align-self-md-center my-auto">

                            <div className='login-details'>
                                <div className='text-center mb-1'>
                                    <img src={Logo} alt="" className='img-fluid logo-img' />
                                </div>
                                <div className='login_form_head'>
                                    {/* <h4>REXO ERP DEALERS </h4> */}
                                    <h5>Please Log into your account</h5>
                                </div>
                                <LoginForm />

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
