import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var StockReportListRes;
export const StockReportListTodo = createAsyncThunk('StockReportList', async (data) => {
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'items_stocks_list',
            headers: { "Content-Type": "application/json" },
            data:{
                "iware_house_id": localStorage.getItem('iware_house_id'),
                "vcustomer_code":localStorage.getItem('vcustomer_code')
            },
        })
        return StockReportListRes = res.data

    } catch (error) {
        return StockReportListRes = error.response.data
    }
});

const StockReportListSlice = createSlice({
    name: 'StockReportList',
    initialState: {
        data: null,
    },
    extraReducers: (builder) => {
        builder.addCase(StockReportListTodo.fulfilled, (state, action) => {
            state.data = action.payload;
            // console.log('userList', state.data)
        });
        builder.addCase(StockReportListTodo.rejected, (state, action) => {
            // console.log('token_error', action)
        })
    }
});

export default StockReportListSlice.reducer; 