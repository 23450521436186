import React, { useEffect, useState } from 'react';
import { FaRegBell, FaUserCircle } from "react-icons/fa";
import { MdOutlineEmail, MdLogout } from "react-icons/md";
import { Link, useNavigate } from 'react-router-dom';
import { IoReorderThree } from "react-icons/io5";
import { RiLockPasswordLine } from "react-icons/ri";
import logo from '../assest/images/rexoerp-logo.png'
import Sidebar from './Sidebar';
import { ToastContainer } from 'react-toastify';
import UpdatePsw from '../pages/login/UpdatePsw';

export default function Navbar() {
    // const [adminpsw, setAdminpsw] = useState(false)

    // const handlePswUpdate = () => {
    //     setAdminpsw(true);
    // }

    const [show, setShow] = useState(false)

    const handlePswChnge = () => {
        setShow(true)
    }

    const navigate = useNavigate();
    const logout = () => {
        localStorage.clear();
        navigate('/login');
    }

    const [sidebar, setSidebar] = useState(true);

    var type = localStorage.getItem('type')

    useEffect(() => {
        if (sidebar) {
            var marginAll = document.getElementById("content");
            marginAll.classList.add("margin-all-page")
        } else {
            var marginAll = document.getElementById("content");
            marginAll.classList.remove("margin-all-page")
        }
    })

    return (
        <>
            <nav style={{ position: 'relative' }}>
                <div className='container-fluid' style={{ position: 'fixed', zIndex: "999", top: '0', backgroundColor: "white" }}>
                    <header className=' ' id='property-header'>
                        <div className="row  ">
                            <div className="col-md-12 p-0">
                                <div className='d-flex justify-content-between align-items-center'>
                                    <div className=" d-flex align-items-center">
                                        <div className='header-logo-section ' id='header-dekstop-logo'>
                                            <img src={logo} className='img-fluid header-logo ' alt="property-logo" />
                                        </div>
                                        <div>
                                            <a type='button' className='sidebar-btn d-none d-lg-block ' style={{ marginTop: "1px" }} onClick={() => setSidebar(!sidebar)} > <IoReorderThree style={{ color: "#105684" }} size={35} /></a>
                                        </div>
                                    </div>
                                    <div className='d-flex align-items-center'>
                                        <div className='ms-4'>
                                            <div className="dropdown" style={{ marginBottom: "-12px" }}>
                                                <p className=' profile_drop_toggal dropdown-toggle' type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <span style={{ fontSize: "18px", fontWeight: '500' }}>Welcome ! { localStorage.getItem('vuser_name')} <FaUserCircle size={25} className='mb-1' style={{ color: "#ff0002" }} /> </span>
                                                </p>
                                                <ul className="dropdown-menu profile_drop">
                                                    <li><button className="dropdown-item"  type='button' onClick={handlePswChnge}><RiLockPasswordLine className=' me-1 ' size={25} style={{ color: '#ff0002' }} /> Change Password</button></li>
                                                    <li><button className="dropdown-item" type='button' onClick={logout}  ><MdLogout className=' me-1 ' size={25} style={{ color: '#ff0002' }} /> Logout</button></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>
                </div>
            </nav>
            {sidebar && (<Sidebar />)}

            {/* <PasswordUpdate adminpsw={adminpsw} setAdminpsw={setAdminpsw} /> */}
            <ToastContainer />
            <UpdatePsw show={show} setShow={setShow}/>
        </>
    )
}
