import React from 'react'
import Button from 'react-bootstrap/Button'

export default function SaveButton(props) {
    return (
        <>
            <Button type='submit' variant="" className='save_btn'>{props.name}</Button>
        </>
    )
}
