import React, { useState, useEffect } from 'react'
import DataTable from '../../../component/data_table/DataTable'
import { Button, Table } from 'react-bootstrap'
import { LuPrinter } from 'react-icons/lu';
import { useReactToPrint } from 'react-to-print'
import Flatpickr from 'react-flatpickr'
import PerfectScrollbar from 'react-perfect-scrollbar'
import 'react-perfect-scrollbar/dist/css/styles.css';
import { FaRegFileExcel } from 'react-icons/fa6';
import { Form } from 'react-bootstrap'
// import { exceluploadRes, exceluploadTodo } from '../../redux/slices/excelFileUpload';
import xlsx from "json-as-xlsx"
import "flatpickr/dist/themes/light.css"
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { ReceiveReportListRes, ReceiveReportListTodo } from '../../../redux/slices/store/report-list/ReceiveReportList';
import Loader from '../../../component/loader/Loader';
import ReceiveReportPdf from './ReceiveReportPdf';

export default function ReceiveReportList() {
    const [loading, setLoading] = useState(false)

    const dispatch = useDispatch()
    const receiveReport = useSelector(state => state?.ReceiveReportList?.data?.data)

    const totalAmt = receiveReport?.reduce((acc, item) => acc + item.mtotal_amt, 0);
    const [pdfModal, setPdfModal] = useState(false)
    const [filterDate, setFilterDate] = useState('')
    const [reportData, setReportData] = useState([])


    const handlePdf = () => {
        setPdfModal(true)
        setReportData(receiveReport)
    }


    const handlePrint = useReactToPrint({
        content: () => document.getElementById('pdf-content'),
    });




    const handleDate = (data) => {
        setLoading(true)
        dispatch(ReceiveReportListTodo(data)).then(() => report_res())
    }

    const report_res = () => {
        if (ReceiveReportListRes?.success) {
            setLoading(false)
        } else {
            setLoading(false)
        }
    }

    useEffect(() => {
        // Reset the date field after the data is successfully fetched
        // if (!loading) {
        setLoading(true)
        dispatch(ReceiveReportListTodo('00/00/0000')).then(() => report_res()); // Reset the date field
        // }
    }, []);

    const ReceiveReportData = []

    const ReceiveReportDataRender = receiveReport && receiveReport.map((value, index) => ReceiveReportData.push({
        "Sn": index + 1,
        "skf_item_name": value.vitem_description,
        "opil_item_name": value.vitem_name,
        "opil_item_code": value.vdrawing_code,
        "vreceived_date": value.vreceived_date,
        "quantity": value.fquantity,
        "mmrp_rate": value.mmrp_rate.toFixed(2),
        "mmrp_discount": value.mmrp_discount.toFixed(2),
        "mrate": value.mrate.toFixed(2),
        "mtotal_amt": value.mtotal_amt.toFixed(2),
    }))

    const downloadFile = () => {
        let exceldata = [
            {
                columns: [
                    { label: "S.NO.", value: "Sn" },
                    { label: "SKF ITEM NAME", value: "skf_item_name" },
                    { label: "OPIL ITEM NAME", value: "opil_item_name" },
                    { label: "OPIL ITEM CODE ", value: "opil_item_code" },
                    { label: "RECEIVE DATE ", value: "vreceived_date" },
                    { label: "QUANTITY", value: "quantity" },
                    { label: "BASIC RATE", value: "mmrp_rate" },
                    { label: "DISCOUNT", value: "mmrp_discount" },
                    { label: "RATE", value: "mrate" },
                    { label: "TOTAL VALUE", value: "mtotal_amt" },
                ],
                content: ReceiveReportData
            },

        ]
        let settings = {
            fileName: "ExcelSheet1",
        }
        xlsx(exceldata, settings)

    }
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="d-flex justify-content-between align-items-center layout_head">
                            <div className='d-flex justify-content-start align-items-center'>
                                <div>
                                    <h5>Receive Report List</h5>
                                </div>
                                <div className='ms-3 mb-2'>
                                    <Flatpickr
                                        className='form-control form_input flatePicker'
                                        placeholder={'Date Filter'}
                                        option={{
                                            altInput: true,
                                            altFormat: "F j, Y",
                                            dateFormat: 'Y-m-d',
                                            minDate: new Date().toISOString().split("T")[0],
                                            maxDate: '',
                                            disable: [],
                                            mode: "single",
                                            defaultDate: [],
                                            conjunction: "",
                                            static: true
                                        }}
                                        name='deadline'
                                        // value={values.deadline || ''}
                                        onChange={(e) => {
                                            handleDate(moment(e[0]).format("DD/MM/YYYY"))
                                            setFilterDate(moment(e[0]).format("DD/MM/YYYY"))
                                        }
                                        }
                                    />

                                </div>
                            </div>
                            {receiveReport?.length ? <div className='d-flex align-items-center'>
                                <Button variant='primary' size='sm' onClick={handlePdf}>
                                    Print <LuPrinter />
                                </Button>
                                <Button className='ms-2' variant='primary' size='sm' onClick={() => downloadFile()} >
                                    Excel Download <FaRegFileExcel />
                                </Button>
                            </div> : ''}

                        </div>
                        {/* <div className="col-md-12 mt-1" >
                            <DataTable
                                rowData={receiveReport}
                                columnDefs={columnDefs}
                                height={'75vh'}
                            />

                        </div> */}
                        <div className='col-md-12 mt-3' id='pdf-content' >
                            {/* <PerfectScrollbar style={{ height: '77vh' }}> */}
                            <Table striped bordered hover className=' '>
                                <thead className=''>
                                    <tr>
                                        <th style={{ width: "20px" }}>S.NO</th>
                                        <th>SKF ITEM NAME</th>
                                        <th>OPIL ITEM NAME</th>
                                        <th>OPIL ITEM CODE</th>
                                        <th>RECEIVE DATE</th>
                                        <th>RECEIVE QTY.</th>
                                        <th>BASIC RATE</th>
                                        <th>DISCOUNT</th>
                                        <th>RATE</th>
                                        <th>TOTAL VALUE</th>
                                    </tr>
                                </thead>
                                {
                                    loading ?
                                        <tbody>
                                            <tr>
                                                <td className='text-center p-5' style={{ fontSize: "18px", color: '#9d9d9d', fontWeight: "500" }} colSpan={10}> <div className='d-flex justify-content-center '>
                                                    <Loader />
                                                </div></td>
                                            </tr>
                                        </tbody>
                                        :
                                        receiveReport?.length ?
                                            <tbody >
                                                {receiveReport?.map((value, index) =>
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>{value.vitem_description}</td>
                                                        <td> {value.vitem_name}</td>
                                                        <td > {value.vdrawing_code}</td>
                                                        <td > {value.vreceived_date}</td>
                                                        <td >{value.fquantity}</td>
                                                        <td>{value.mmrp_rate.toFixed(2)}</td>
                                                        <td>{value.mmrp_discount}</td>
                                                        <td>{value.mrate.toFixed(2)}</td>
                                                        <td>{value.mtotal_amt.toFixed(2)}</td>
                                                    </tr>)}
                                                <tr>
                                                    <td style={{ textAlign: 'center', fontSize: '16px', fontWeight: '600' }} colSpan={9}>Total</td>
                                                    <td style={{ fontSize: '16px', fontWeight: '600' }}>{totalAmt.toFixed(2)}</td>
                                                </tr>
                                            </tbody> :
                                            <tbody>
                                                <tr>
                                                    <td className='text-center p-5' style={{ fontSize: "18px", color: '#9d9d9d', fontWeight: "500" }} colSpan={10}>DATA NOT FOUND</td>
                                                </tr>
                                            </tbody>}

                            </Table>
                            {/* </PerfectScrollbar> */}
                        </div>
                    </div>
                </div>
            </div>
            <ReceiveReportPdf pdfModal={pdfModal} setPdfModal={setPdfModal} filterDate={filterDate} setFilterDate={setFilterDate} reportData={reportData} setReportData={setReportData} />
        </>
    )
}
