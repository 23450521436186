import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var StockListRes;
export const StockListTodo = createAsyncThunk('StockList', async (data) => {
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'items_list',
            headers: { "Content-Type": "application/json" },
            data:{
                "iware_house_id": localStorage.getItem('iware_house_id'),
                "vitem_name": ''
            },
        })
        return StockListRes = res.data

    } catch (error) {
        return StockListRes = error.response.data
    }
});

const StockListSlice = createSlice({
    name: 'StockList',
    initialState: {
        data: null,
    },
    extraReducers: (builder) => {
        builder.addCase(StockListTodo.fulfilled, (state, action) => {
            state.data = action.payload;
            // console.log('userList', state.data)
        });
        builder.addCase(StockListTodo.rejected, (state, action) => {
            // console.log('token_error', action)
        })
    }
});

export default StockListSlice.reducer; 