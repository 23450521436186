import React, { useState } from 'react'
import { LuBadgeInfo, LuEye, LuFileEdit, LuTrash2, LuEyeOff } from 'react-icons/lu'
import { Tooltip } from 'react-tooltip'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { FaFilePdf } from "react-icons/fa6";
import { ReceiveItemDetailsTodo } from '../../../redux/slices/store/receiveitem/ReceiveItemDetails'

export default function StockListAction(props) {
    const dispatch = useDispatch()
    const { bool, setBool, modal, setModal, pdfData, setPdfData } = props

    const navigate = useNavigate()
    const challan_code = props?.data?.vchallan_code
    const item_code = props?.data?.vitem_code

    const pdf_genrator = () => {
        setModal(true)
        setPdfData(props?.data)
        dispatch(ReceiveItemDetailsTodo({ 'challan_code': challan_code, 'item_code': item_code }))
    }

    return (
        <>
            <div>
                <FaFilePdf size={20} className='details text-danger ms-3'
                    onClick={() => pdf_genrator()}
                    data-tooltip-id='details'
                    data-tooltip-content=" PDF Generate!"
                    data-tooltip-place="bottom"
                />
                <Tooltip id='edit' className='bg-success tooltip_msg' />
                <Tooltip id='del' className='bg-danger tooltip_msg' />
                <Tooltip id='details' className='bg-danger tooltip_msg' />
                <Tooltip id='active' className='bg-secondary tooltip_msg' />
                <Tooltip id='deactivate' className='bg-secondary tooltip_msg' />

            </div>
        </>
    )
}
