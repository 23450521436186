import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { StockListRes, StockListTodo } from '../../../redux/slices/store/stock-list/StockList'
import Loader from '../../../component/loader/Loader'
import DataTablePagination from '../../../component/data_table/DataTablePagination'
import { LuChevronLeft, LuChevronRight } from 'react-icons/lu'
import DataTable from '../../../component/data_table/DataTable'
import StockListAction from '../../../component/data_table/table_action/StockListAction'
import PDFFile from './PDFFile'
import { ReceiveItemListRes, ReceiveItemListTodo } from '../../../redux/slices/store/receiveitem/ReceiveItemList'

export default function ReceiveItemList() {
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const ReceiveItemListData = useSelector(state => state?.ReceiveItemList?.data?.data)
    // const TotalCount = useSelector(state => state?.StockList?.data?.data?.count)
    const [pageLimit, setPageLimit] = useState(10)
    const [pageSize, setPageSize] = useState(0)
    const [modal, setModal] = useState(false)
    const [bool, setBool] = useState(false)
    const [pdfData, setPdfData] = useState('')

    useEffect(() => {
        setLoading(true)
        dispatch(ReceiveItemListTodo()).then(() => list_res())
    }, [])

    const list_res = () => {
        if (ReceiveItemListRes?.success) {
            setLoading(false)
        } else {
            setLoading(false)
        }
    }

    const columnDefs = [
        {
            headerName: 'S.NO',
            valueGetter: 'node.rowIndex + 1',
            filter: 'false',
            suppressMenu: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
            maxWidth: 80
        },
        {
            headerName: 'SKF ITEM NAME',
            field: 'vitem_description',
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
        },
        {
            headerName: 'OPIL ITEM NAME',
            field: 'vitem_name',
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
        },
        {
            headerName: 'OPIL ITEM CODE',
            field: 'vdrawing_code',
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
        },
        // {
        //     headerName: 'MAKE',
        //     field: 'vparent_group_name',
        //     filter: 'agTextColumnFilter',
        //     suppressMenu: true,
        //     filter: true,
        //     sortable: true,
        //     floatingFilter: false,
        //     maxWidth: 150

        //     // wrapText: true,
        //     // autoHeight: true,
        // },
        {
            headerName: 'RECEIVE DATE',
            field: 'vreceived_date',
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            maxWidth: 200

            // wrapText: true,
            // autoHeight: true,
        },
        {
            headerName: 'RECEIVE QT.',
            field: 'fquantity',
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            maxWidth: 150
            // wrapText: true,
            // autoHeight: true,
        },
        {
            headerName: 'ISSUE QT.',
            field: '',
            cellRenderer: params => params?.data?.fquantity - params?.data?.mstock,
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            maxWidth: 150
            // wrapText: true,
            // autoHeight: true,
        },
        {
            headerName: 'STOCK QT.',
            field: 'mstock',
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            maxWidth: 150
            // wrapText: true,
            // autoHeight: true,
        },

        {
            field: 'ACTIONS',
            cellRenderer: StockListAction,
            cellRendererParams: {
                bool: bool,
                setBool: setBool,
                pdfData:pdfData,
                setPdfData:setPdfData,
                // setLoading: setLoading,
                modal : modal,
                setModal: setModal,
            },
            // wrapText: true,
            maxWidth:100,
            autoHeight: true,
            pinned: 'right',
        }
    ]


    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="layout_head">
                            <h5>Receive Item List</h5>
                        </div>
                    </div>
                    {
                        loading ?
                            <div>
                                <Loader />
                            </div> :
                            ReceiveItemListData && ReceiveItemListData.length ?
                                <DataTable
                                    rowData={ReceiveItemListData}
                                    columnDefs={columnDefs}
                                    height={'70vh'}
                                /> :
                                <div className='empty_section mt-3'>
                                    <span>DATA NOT FOUND</span>
                                </div>
                    }
                </div>
            </div>
            <PDFFile modal={modal} setModal={setModal} pdfData={pdfData}/>
        </>
    )
}
