import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var ItemListRes;
export const ItemListTodo = createAsyncThunk('ItemList', async (data) => {
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'items_list',
            headers: { "Content-Type": "application/json" },
            data: {
                "iware_house_id": localStorage.getItem('iware_house_id'),
                "vitem_name": data ? data : ''
            },
        })
        return ItemListRes = res.data

    } catch (error) {
        return ItemListRes = error.response.data
    }
});

const ItemListSlice = createSlice({
    name: 'ItemList',
    initialState: {
        data: null,
    },
    extraReducers: (builder) => {
        builder.addCase(ItemListTodo.fulfilled, (state, action) => {
            state.data = action.payload;
            // console.log('userList', state.data)
        });
        builder.addCase(ItemListTodo.rejected, (state, action) => {
            // console.log('token_error', action)
        })
    }
});

export default ItemListSlice.reducer; 