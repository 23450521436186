import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var UpdateQuantityRes;
export const UpdateQuantityTodo = createAsyncThunk('UpdateQuantity', async (data) => {
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'updated_receive_quantity',
            data: {
              
                "vitem_code": data?.vitem_code,
                "vchallan_code": data?.vchallan_code,
                "mreceived_qty": data?.receive_fquantity
            },
            headers: { "Content-Type": "application/json" }
        })
        return UpdateQuantityRes = res.data

    } catch (error) {
        return UpdateQuantityRes = error.response.data
    }
})