import React from 'react'
import { MdCancel } from "react-icons/md";
import { Form } from 'react-bootstrap'
import { useFormik } from 'formik'
import { ToastContainer, toast } from 'react-toastify';
import Swal from 'sweetalert2'
import SaveButton from '../../../component/buttons/SaveButton';
import * as Yup from "yup";


export default function EditSaveItems(props) {
    const { editItems, setEditItems, orderItems, setOrderItems, indexValue } = props;
    const itemsData = orderItems[indexValue]

    const initialValues = {
        quantity: itemsData && itemsData.values && itemsData.values.quantity,
        remark: itemsData && itemsData.values && itemsData.values.remark,
    }
    const AddItemSchema = Yup.object({
        // quantity: Yup.number().min(1, "Quantity Can't be less then 1").required('Quantity is required'),
        quantity: Yup.number()
        .min(1, "Quantity can't be less than 1")
        .required('Quantity is required')
        .test('is-in-stock', 'Quantity is more than stock', function(value) {
          return value <= itemsData?.mstock;
        })
    })
    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues: initialValues,
        validationSchema: AddItemSchema,
        enableReinitialize: true,

        onSubmit: (values, action) => {

            const newObj = ({ ...orderItems[indexValue], values })
            orderItems[indexValue] = newObj;

            setTimeout(() => {
                setEditItems(false)
            }, 500);
        },
    })

    return (
        <>
            <div className={editItems ? 'modal fade show d-block' : 'modal fade show d-none'} style={{ background: 'rgba(0,0,0,0.8)' }}>
                <div className="modal-dialog modal-dialog-centered modal-lg  ">
                    <div className="modal-content pb-5" >
                        <div className="modal-header d-flex justify-content-between "  >
                            <h5 className='text-center ' >Update Items </h5>
                            <span onClick={() => { setEditItems(false); }}><MdCancel style={{ color: 'red' }} size={25} className="mb-2" /></span>
                        </div>
                        <div className='modal-body  p-4 '>
                        <div className='item_details '>
                                <div className=''>
                                    {/* <p> Code : <span>{itemsData && itemsData.vitem_code}</span></p> */}
                                    <p >SKF ITEM NAME: <span> {itemsData && itemsData.vitem_description} </span></p>
                                </div>
                                <p>OPIL ITEM NAME: <span> {itemsData && itemsData.vitem_name} </span></p>
                                <p >OPIL ITEM CODE : <span> {itemsData && itemsData.vdrawing_code} </span></p>


                                <div className='d-flex'>
                                    <p >STOCK : <span> {itemsData && itemsData.mstock} </span></p>
                                    <p className='ms-5'>MAKE : <span> {itemsData?.vparent_group_name} </span></p>
                                    <p className='ms-5'>RECEIVE DATE : <span> {itemsData?.vreceived_date} </span></p>
                                </div>
                            </div>
                            <div className='mt-3'>
                                <Form onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <Form.Group className="mb-2" controlId="text">
                                                <Form.Label className='form_label'>Quantity</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    className='form_input'
                                                    name='quantity'
                                                    value={values.quantity}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                                {errors.quantity && touched.quantity ? (<span className='text-danger ' style={{ fontSize: "15px" }} >{errors.quantity}</span>) : null}

                                            </Form.Group>
                                        </div>
                                        <div className="col-md-8">
                                            <Form.Group className="mb-2" controlId="text">
                                                <Form.Label className='form_label'>Remark</Form.Label>
                                                <Form.Control
                                                    type="textarea"
                                                    className='form_input'
                                                    name='remark'
                                                    value={values.remark}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />

                                            </Form.Group>
                                        </div>
                                        <div className='d-flex justify-content-end'>
                                            <SaveButton name={'Update'} />
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
