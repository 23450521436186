import React from 'react'
import userlogo from '../assest/images/profile.png'
import { MdDashboard, MdLeaderboard, MdPayment, MdNotifications, MdOutlineEmojiTransportation } from "react-icons/md";
import { BiChevronRight, BiClinic } from "react-icons/bi";
import { HiUsers } from "react-icons/hi";
import { FaUserMd, FaClinicMedical, FaUser, FaStore } from "react-icons/fa";
import { RiHealthBookFill, RiFileList3Fill } from "react-icons/ri";
import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar'
import { Link, NavLink } from 'react-router-dom';
import { Accordion } from 'react-bootstrap';

export default function Sidebar() {
    const userType = localStorage.getItem('type')
    var permission = JSON.parse(localStorage.getItem('permissionList'))

    return (
        <>
            <div id="dashboard-sidebar">
                <div className=" profile-header ">
                    <div className='text-center sidebar-logo-section '>
                        <img src={userlogo} alt="img" className='img-fluid user-img' />
                        <h6 className='mt-3'>{localStorage.getItem('vcustomer_name')} </h6>
                        {/* <h6 className='text-center' style={{textTransform:'capitalize'}}>( {localStorage.getItem('type')} )</h6> */}
                    </div>
                </div>
                <div className="offcanvas-body profile-sidebar-body ">
                    <PerfectScrollbar>
                        <Accordion defaultActiveKey="0">
                            <Accordion.Item className='acc_btn' eventKey="0">
                                <Link to="../dashboard">   <Accordion.Header >  <MdDashboard className='mb-1 me-2' size={20} /> Dashboard</Accordion.Header> </Link>

                            </Accordion.Item >

                            <Accordion.Item className='acc_btn' eventKey="3">
                                <Accordion.Header >  <FaStore className='mb-1 me-2' size={20} /> Store</Accordion.Header>
                                <Accordion.Body>
                                    <div className="accordion-body">
                                        <ul className='acc-list' >
                                            {permission?.map((val, index) =>
                                                <NavLink to={val?.vurl_path} > <li>  <BiChevronRight className='mb-1 me-0' size={20} /> {val?.vurl_name}</li></NavLink>)}
                                            {/* <NavLink to='../receive-item-list'> <li><BiChevronRight className='mb-1 me-0' size={20} />  Received Item List</li></NavLink>
                                            <NavLink to='../material-issue'>  <li><BiChevronRight className='mb-1 me-0' size={20} />   Material Issue</li></NavLink>
                                            <NavLink to='../material-issue-list'>  <li><BiChevronRight className='mb-1 me-0' size={20} />   Material Issue List</li></NavLink>
                                            <NavLink to='../receive-report-list'>  <li><BiChevronRight className='mb-1 me-0' size={20} />   Receive Report List</li></NavLink>
                                            <NavLink to='../issued-report-list'>  <li><BiChevronRight className='mb-1 me-0' size={20} />   Issued Report List</li></NavLink>
                                            <NavLink to='../stock-report-list'>  <li><BiChevronRight className='mb-1 me-0' size={20} />   Stock Report List</li></NavLink> */}
                                            {/* <NavLink to='../brand'>  */}
                                            {/* <li><BiChevronRight className='mb-1 me-0' size={20} />  Material Status Report</li> */}
                                            {/* </NavLink> */}
                                        </ul>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </PerfectScrollbar>
                </div>
            </div>
        </>
    )
}
