import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var ReceiveItemDetailsRes;
export const ReceiveItemDetailsTodo = createAsyncThunk('ReceiveItemDetails', async (data) => {
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'pdf_imtreceive_details',
            headers: { "Content-Type": "application/json" },
            data:{
                "iware_house_id": localStorage.getItem('iware_house_id'),
                "vitem_code": data?.item_code,
                "vchallan_code":data?.challan_code  
            },
        })
        return ReceiveItemDetailsRes = res.data

    } catch (error) {
        return ReceiveItemDetailsRes = error.response.data
    }
});

const ReceiveItemDetailsSlice = createSlice({
    name: 'ReceiveItemDetails',
    initialState: {
        data: null,
    },
    extraReducers: (builder) => {
        builder.addCase(ReceiveItemDetailsTodo.fulfilled, (state, action) => {
            state.data = action.payload;
            // console.log('userList', state.data)
        });
        builder.addCase(ReceiveItemDetailsTodo.rejected, (state, action) => {
            // console.log('token_error', action)
        })
    }
});

export default ReceiveItemDetailsSlice.reducer; 