import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var ApproveMaterialRes;
export const ApproveMaterialTodo = createAsyncThunk('ApproveMaterial', async (data) => {
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'updated_imt_receive_detail',
            data:{
                "vchallan_code":data,
                "iware_house_id":localStorage.getItem('iware_house_id'),
                // "added_by":localStorage.getItem('vcustomer_code')
            },
            headers: { "Content-Type": "application/json" }
        })
        return ApproveMaterialRes = res.data

    } catch (error) {
        return ApproveMaterialRes = error.response.data
    }
})


