import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var UpdateIMTRes;
export const UpdateIMTTodo = createAsyncThunk('UpdateIMT', async (data) => {
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'updated_customer_details',
            data:{
                "vcustomer_code":localStorage.getItem('vcustomer_code'),
                "iware_house_id":localStorage.getItem('iware_house_id'),
                "vitem_code":data?.vitem_code,
                "vcust_model_no":data?.vdrawing_code,
                "vcust_item_name":data?.vitem_name,
                "vchallan_code":data?.vchallan_code,
                'vbin_location': data?.vbin_location
            },
            headers: { "Content-Type": "application/json" }
        })
        return UpdateIMTRes = res.data

    } catch (error) {
        return UpdateIMTRes = error.response.data
    }
})