import React from 'react'
import { Modal } from 'react-bootstrap';
import { Page, Text, View, Document, StyleSheet, PDFViewer } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    page: {
        flexDirection: 'row',
        backgroundColor: 'white',
    },
    section: {
        margin: 10,
        padding: 10,
        // flexGrow: 1
    },
    headingSection: {
        display: "flex",
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    table: {
        display: "table",
        width: "auto",
        borderStyle: "solid",
        borderWidth: 1,
        borderColor: '#bfbfbf',
        margin: '10px 0',
    },
    tableRow: {
        flexDirection: "row",
        flexWrap: "nowrap", // Ensures that the row content doesn't wrapal

    },
    tableColHeader: {
        width: "15%",
        borderStyle: "solid",
        borderWidth: 1,
        borderColor: '#bfbfbf',
        backgroundColor: "#e0e0e0",
        padding: 5,
    },
    tableCol: {
        width: "15%",
        borderStyle: "solid",
        borderWidth: 1,
        borderColor: '#bfbfbf',
        padding: 5,
    },
    tableCell: {
        margin: "auto",
        fontSize: 9,
        textAlign: "left",
        // Ensures content is centered
    },
  
});

export default function MaterialIssuetPdf(props) {
    const { pdfModal, setPdfModal ,orderItems,setOrderItems } = props;
    const handleClose = () => {
        { setPdfModal(false); setOrderItems([]);    }
    }

    const MyDocument = () => (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.section}>
                <View style={styles.headingSection}>
                        <Text>Material Issue </Text>
                        
                    </View>
                    <View style={styles.table}>
                        {/* Table Header */}
                        <View style={styles.tableRow}>
                            <View style={styles.tableColHeader}>
                                <Text style={styles.tableCell}>SKF ITEM NAME</Text>
                            </View>
                            <View style={styles.tableColHeader}>
                                <Text style={styles.tableCell}>OPIL ITEM NAME</Text>
                            </View>
                            <View style={styles.tableColHeader}>
                                <Text style={styles.tableCell}>OPIL ITEM CODE</Text>
                            </View>
                            <View style={styles.tableColHeader}>
                                <Text style={styles.tableCell}>MAKE</Text>
                            </View>
                            <View style={styles.tableColHeader}>
                                <Text style={styles.tableCell}>STOCK</Text>
                            </View>
                            <View style={styles.tableColHeader}>
                                <Text style={styles.tableCell}>QUANTITY</Text>
                            </View>
                            <View style={styles.tableColHeader}>
                                <Text style={styles.tableCell}>RECEIVE DATE</Text>
                            </View>
                           
                        </View>
                        {/* Table Data */}
                        {orderItems?.map((item, index) => (
                            <View style={styles.tableRow} key={index}>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>{item.vitem_description}</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>{item.vitem_name}</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>{item.vdrawing_code}</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>{item.vparent_group_name}</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>{item.mstock}</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>{item?.values?.quantity}</Text>
                                </View>

                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>{item.vreceived_date}</Text>
                                </View>
                              
                            </View>
                        ))}
                    </View>
                </View>
            </Page>
        </Document>
    );

    return (
        <>
            <Modal show={pdfModal} onHide={handleClose} size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>PDF GENRATE</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                      
                        <PDFViewer width="100%" height="600">
                            <MyDocument />
                        </PDFViewer>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}
