import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var IssueReportListRes;
export const IssueReportListTodo = createAsyncThunk('IssueReportList', async (data) => {
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'datewise_issue_material_list',
            headers: { "Content-Type": "application/json" },
            data: {
                "iware_house_id": localStorage.getItem('iware_house_id'),
                "invoice_status": "",
                "dreceived_date": data
            },
        })
        return IssueReportListRes = res.data

    } catch (error) {
        return IssueReportListRes = error.response.data
    }
});

const IssueReportListSlice = createSlice({
    name: 'IssueReportList',
    initialState: {
        data: null,
    },
    extraReducers: (builder) => {
        builder.addCase(IssueReportListTodo.fulfilled, (state, action) => {
            state.data = action.payload;
            // console.log('userList', state.data)
        });
        builder.addCase(IssueReportListTodo.rejected, (state, action) => {
            // console.log('token_error', action)
        })
    }
});

export default IssueReportListSlice.reducer; 