import React from 'react'
import { LuBadgeInfo, LuEye, LuFileEdit, LuTrash2, LuEyeOff } from 'react-icons/lu'
import { Tooltip } from 'react-tooltip'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'

export default function ReceiveMaterialAction(props) {
    const dispatch = useDispatch()
    const { bool, setBool } = props
    const navigate = useNavigate()
    const customer_details = () => {
        navigate('../receive-material-details', { state: props.data && props.data })
    }


    return (
        <>
            <div>
                <LuBadgeInfo size={20} className='details text-primary ms-3'
                    onClick={() => customer_details()}
                    data-tooltip-id='details'
                    data-tooltip-content=" Details!"
                    data-tooltip-place="bottom"
                />
           
                <Tooltip id='edit' className='bg-success tooltip_msg' />
                <Tooltip id='del' className='bg-danger tooltip_msg' />
                <Tooltip id='details' className='bg-primary tooltip_msg' />
                <Tooltip id='active' className='bg-secondary tooltip_msg' />
                <Tooltip id='deactivate' className='bg-secondary tooltip_msg' />

            </div>
        </>
    )
}
