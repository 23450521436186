import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DataTable from '../../../component/data_table/DataTable'
import Loader from '../../../component/loader/Loader'
import ReceiveMaterialAction from '../../../component/data_table/table_action/RecieveMaterialAction'
import { ReceiveMaterialListRes, ReceiveMaterialListTodo } from '../../../redux/slices/store/receive-material/ReceiveMaterialList'



export default function ReceiveMateriaList() {
    const [loading, setLoading] = useState(false)
    const [bool, setBool] = useState(false)
    const recievematerial = useSelector(state=> state?.ReceiveMaterial?.data?.data)
    const dispatch = useDispatch()

    useEffect(() => {
        setLoading(true)
        dispatch(ReceiveMaterialListTodo()).then(() => customerFun())
    }, [])

    const customerFun = () => {
        if (ReceiveMaterialListRes && ReceiveMaterialListRes.success) {
            setLoading(false)
        } else {
            setLoading(false)
        }
    }

    

    const columnDefs = [
        {
            headerName: 'S.NO',
            valueGetter: 'node.rowIndex + 1',
            filter: 'false',
            suppressMenu: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
            maxWidth: 80
        },
        {
            headerName: 'IMT CODE',
            field: 'vitm_code',
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
        },
   

        {
            headerName: 'FROM WAREHOUSE',
            field: 'vfrom_ware_house',
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
        },
        {
            headerName: 'TO WAREHOUSE',
            field: 'vto_ware_house',
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
        },
        {
            headerName: 'SEND BY',
            field: 'vsend_by',
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
            maxWidth: 130
        },
        {
            headerName: 'IMT DATE',
            field: 'danx_date',
            filter: 'agTextColumnFilter',
            // cellRenderer: params => new Date(params.data.danx_date).toLocaleDateString('en-GB'),
            suppressMenu: true,
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            maxWidth: 150

        },
        // {
        //     headerName: 'STATUS',
        //     field: 'status',
        //     filter: 'agTextColumnFilter',
        //     cellRenderer: params => params.data.status === 'active' ? <span className='badge text-bg-success'>Active</span> : <span className='badge text-bg-danger'>Inactive</span>,
        //     suppressMenu: true,
        //     filter: true,
        //     sortable: true,
        //     floatingFilter: false,
        //     // wrapText: true,
        //     // autoHeight: true,
        // },
        {
            field: 'ACTIONS',
            cellRenderer: ReceiveMaterialAction,
            cellRendererParams: {
                bool: bool,
                setBool: setBool,
                setLoading: setLoading,
            },
            // wrapText: true,
            autoHeight: true,
            pinned: 'right',
            maxWidth: 120
        }
    ]

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="layout_head">
                            <h5> Receive Material </h5>
                        </div>
                        {loading ?
                            <div className='d-flex juistify-content-center mt-5 '>
                                <Loader />
                            </div> :
                            recievematerial?.length ?
                                <>

                                    <div className="col-md-12 mt-1">
                                        <DataTable
                                            rowData={recievematerial}
                                            columnDefs={columnDefs}
                                            height={'75vh'}
                                        />
                                        
                                    </div>

                                </>
                                 :
                                <div className='empty_section'>
                                    <span>DATA NOT FOUND</span>
                                </div>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}
