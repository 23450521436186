import React from 'react'

export default function LoginButton(props) {
  return (
    <>
     <button type='submit' className='btn login_btn'>
                {props.spiner ? <p style={{ marginBottom: '0px' }}> Login... <span className=" ms-2 spinner-border spinner-border-sm" aria-hidden="true"></span> </p> : props.name}
            </button>
    </>
  )
}
