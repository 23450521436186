import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var MaterialIssueListRes;
export const MaterialIssueListTodo = createAsyncThunk('MaterialIssueList', async (data) => {
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'material_issue_list',
            data:{
                "iware_house_id": localStorage.getItem('iware_house_id'),
            },
            headers: { "Content-Type": "application/json" },
          
        })
        return MaterialIssueListRes = res.data

    } catch (error) {
        return MaterialIssueListRes = error.response.data
    }
});

const MaterialIssueListSlice = createSlice({
    name: 'MaterialIssueList',
    initialState: {
        data: null,
    },
    extraReducers: (builder) => {
        builder.addCase(MaterialIssueListTodo.fulfilled, (state, action) => {
            state.data = action.payload;
            // console.log('userList', state.data)
        });
        builder.addCase(MaterialIssueListTodo.rejected, (state, action) => {
            // console.log('token_error', action)
        })
    }
});

export default MaterialIssueListSlice.reducer; 