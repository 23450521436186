import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var dashboardRes;
export const dashboardTodo = createAsyncThunk('dashboard', async (data) => {
    // console.log("data", data)
    var type = localStorage.getItem('type')
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'abspl_dashboard',
            headers: { "Content-Type": "application/json" },
            data: {
                "iware_house_id": localStorage.getItem('iware_house_id'),
            }
        })
        return dashboardRes = res.data

    } catch (error) {
        return dashboardRes = error.response.data
    }
});

const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState: {
        data: null,
    },
    extraReducers: (builder) => {
        builder.addCase(dashboardTodo.fulfilled, (state, action) => {
            state.data = action.payload;
            // console.log('userList', state.data)
        });
        builder.addCase(dashboardTodo.rejected, (state, action) => {
            // console.log('token_error', action)
        })
    }
});

export default dashboardSlice.reducer; 