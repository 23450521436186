import React, { useEffect, useState } from 'react'
import DataTable from '../../../component/data_table/DataTable'
import { Button, Table } from 'react-bootstrap'
import { LuPrinter } from 'react-icons/lu';
import { useReactToPrint } from 'react-to-print'
import PerfectScrollbar from 'react-perfect-scrollbar'
import 'react-perfect-scrollbar/dist/css/styles.css';
import { FaRegFileExcel } from 'react-icons/fa6';
import { Form } from 'react-bootstrap'
// import { exceluploadRes, exceluploadTodo } from '../../redux/slices/excelFileUpload';
import xlsx from "json-as-xlsx"
import Swal from 'sweetalert2'
import { useDispatch, useSelector } from 'react-redux';
import { StockReportListRes, StockReportListTodo } from '../../../redux/slices/store/report-list/StockReportList';
import Loader from '../../../component/loader/Loader';
import { toast, ToastContainer } from 'react-toastify'
import { useFormik, FieldArray, FormikProvider } from 'formik';
import { UpdateIMTRes, UpdateIMTTodo } from '../../../redux/slices/store/receive-material/UpdateImt';
import StockReportPdf from './StockReportPdf';


export default function StockReportList() {
    const handlePrint = useReactToPrint({
        content: () => document.getElementById('pdf-content'),
    });
    const [loading, setLoading] = useState(true)
    const dispatch = useDispatch()
    const StockReportList = useSelector(state => state?.StockReportList?.data?.data)
    const [bool, setBool] = useState(false)

    const [pdfModal, setPdfModal] = useState(false)
    const [reportData, setReportData] = useState([])

    const handlePdf = () => {
        setPdfModal(true)
        setReportData(StockReportList)
    }

    const totalAmt = StockReportList?.reduce((acc, item) => acc + item.mtotal_amt, 0);

    useEffect(() => {
        setLoading(true)
        dispatch(StockReportListTodo()).then(() => report_res())
    }, [bool])

    const report_res = () => {
        if (StockReportListRes?.success) {
            setLoading(false)
        } else {
            setLoading(false)
        }
    }

    const cont_data = StockReportList?.map((value) => ({
        vitem_description: value?.vitem_description,
        vitem_name: value?.vitem_name,
        vdrawing_code: value?.vdrawing_code,
        mstock: value?.mstock,
        mreorder_level: value?.mreorder_level,
        vbin_location: value?.vbin_location,
        mshort_fall_qty: value?.mshort_fall_qty,
        vchallan_code: value?.vchallan_code,
        vitem_code: value?.vitem_code,
        mrate: value.mrate,
        mtotal_amt: value.mtotal_amt

    }))

    const initialValues = {
        data: cont_data
    }

    const formik = useFormik({
        initialValues: initialValues,
        enableReinitialize: true,

        onSubmit: (values, action) => {
        },
    })

    const updateItem = (data) => {

        {
            data?.vbin_location === '' || data?.vbin_location === null ?
                Swal.fire(
                    "Items Update",
                    'Empty Filed is Not Allow',
                    'error'
                ) :
                dispatch(UpdateIMTTodo(data)).then(() => update_res())
        }
    }

    const update_res = () => {
        if (UpdateIMTRes?.success) {
            toast.success(UpdateIMTRes?.message, { position: 'bottom-right' })
            setBool(!bool)
        } else {
            toast.error(UpdateIMTRes?.message, { position: 'bottom-right' })
        }
    }



    const ReceiveReportData = []

    const ReceiveReportDataRender = StockReportList && StockReportList.map((value, index) => ReceiveReportData.push({
        "Sn": index + 1,
        "skf_item_name": value.vitem_description,
        "opil_item_name": value.vitem_name,
        "opil_item_code": value.vdrawing_code,
        "mstock": value.mstock,
        "mreorder_level": value.mreorder_level,
        "mshort_fall_qty": value.mshort_fall_qty,
        "vbin_location": value.vbin_location,
        "mrate": value.mrate.toFixed(2),
        "mtotal_amt": value.mtotal_amt.toFixed(2),

        // "remark": value.cost,
    }))

    const downloadFile = () => {
        let exceldata = [
            {
                columns: [
                    { label: "S.NO.", value: "Sn" },
                    { label: "SKF ITEM NAME", value: "skf_item_name" },
                    { label: "OPIL ITEM NAME", value: "opil_item_name" },
                    { label: "OPIL ITEM CODE ", value: "opil_item_code" },
                    { label: "STOCK", value: "mstock" },
                    { label: "MIN QTY.", value: "mreorder_level" },
                    { label: "REQUIRED QTY", value: "mshort_fall_qty" },
                    { label: "BIN LOCATION", value: "vbin_location" },
                    { label: "RATE", value: "mrate" },
                    { label: "TOTAL VALUE", value: "mtotal_amt" },

                ],
                content: ReceiveReportData
            },

        ]
        let settings = {
            fileName: "ExcelSheet1",
        }
        xlsx(exceldata, settings)

    }
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="d-flex justify-content-between align-items-center layout_head">

                            <h5>Stock Report List</h5>
                            {StockReportList?.length ? <div className='d-flex align-items-center'>
                                <Button variant='primary' size='sm' onClick={handlePdf}>
                                    Print <LuPrinter />
                                </Button>
                                <Button className='ms-2' variant='primary' size='sm' onClick={() => downloadFile()} >
                                    Excel Download <FaRegFileExcel />
                                </Button>
                            </div> : ""}

                        </div>
                        {loading ?
                            <div className='d-flex justify-content-center mt-5'>
                                <Loader />
                            </div> :

                            <div className='col-md-12 mt-3' id='pdf-content' >
                                {/* <PerfectScrollbar style={{ height: '77vh' }}> */}
                                <FormikProvider value={formik}>
                                    <form onSubmit={formik.handleSubmit}>
                                        <Table striped bordered hover className=' '>
                                            <thead className=''>
                                                <tr>
                                                    <th style={{ width: "20px" }}>S.NO</th>
                                                    <th>SKF ITEM NAME</th>
                                                    <th>OPIL ITEM NAME</th>
                                                    <th>OPIL ITEM CODE</th>
                                                    <th>STOCK</th>
                                                    <th>MIN QTY. </th>
                                                    <th>REQUIRED QTY</th>
                                                    <th>RATE</th>
                                                    <th>TOTAL VALUE</th>
                                                    <th>BIN LOCATION</th>
                                                    <th>ACTION</th>
                                                </tr>
                                            </thead>


                                            <FieldArray
                                                name="data"
                                                render={(arrayHelpers) => (
                                                    <tbody>
                                                        {formik && formik.values && formik.values.data && formik.values.data.map((value, index) => (
                                                            <tr key={index}>
                                                                <td>{index + 1}</td>
                                                                <td>{value.vitem_description}</td>
                                                                <td>{value.vitem_name}</td>
                                                                <td>{value.vdrawing_code}</td>
                                                                <td>{value.mstock}</td>
                                                                <td>{value.mreorder_level}</td>
                                                                <td>{value.mshort_fall_qty}</td>
                                                                <td>{value.mrate.toFixed(2)}</td>
                                                                <td>{value.mtotal_amt.toFixed(2)}</td>
                                                                <td><Form.Control
                                                                    type="text"
                                                                    className='form_input'
                                                                    name={`data[${index}].vbin_location`}
                                                                    style={{ width: '150px' }}
                                                                    value={formik.values.data[index].vbin_location}
                                                                    onChange={formik.handleChange}
                                                                    onBlur={formik.handleBlur}
                                                                /> </td>
                                                                <td style={{ width: '70px' }}>
                                                                    <Button className='btn update_btn' onClick={() => { updateItem(value) }} type="submit">UPDATE</Button>
                                                                </td>
                                                            </tr>))}
                                                        <tr>
                                                            <td style={{ textAlign: 'center', fontSize: '16px', fontWeight: '600' }} colSpan={8}>Total</td>
                                                            <td style={{ fontSize: '16px', fontWeight: '600' }}>{totalAmt.toFixed(2)}</td>
                                                        </tr>
                                                    </tbody>
                                                )}
                                            />


                                        </Table>
                                    </form>
                                </FormikProvider>
                                {/* </PerfectScrollbar> */}
                            </div>}
                    </div>
                </div>
            </div>
            <ToastContainer />
            <StockReportPdf pdfModal={pdfModal} setPdfModal={setPdfModal} reportData={reportData} setReportData={setReportData} />

        </>
    )
}
