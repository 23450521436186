import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var changePswRes;
export const changePswTodo = createAsyncThunk('changePsw', async (data) => {
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'password_updated',
            data: {
                "id": data.id,
                "Password": data.new_psw,
                "confirmPassword":  data.confirm_psw    
            },
            headers: { "Content-Type": "application/json" }
        })
        return changePswRes = res.data

    } catch (error) {
        return changePswRes = error.response.data
    }
})


