import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { Modal } from 'react-bootstrap';
import { BiHide, BiShow } from 'react-icons/bi';
import { ToastContainer, toast } from 'react-toastify';
import SaveButton from '../../component/buttons/SaveButton';
import Loader from '../../component/loader/Loader';
import { changePswRes, changePswTodo } from '../../redux/slices/LoginSlice/ChangePsw';

export default function UpdatePsw(props) {
  const { show, setShow, user, setuser } = props;
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const handleClose = () => setShow(false);
  const [currentPsw, setCurrentPsw] = useState(false);
  const [newPswShow, setNewPswShow] = useState(false);
  const [confirmPswShow, setconfirmPswShow] = useState(false);

  const initialValues = {
    id: user,
    current_psw: '',
    new_psw: "",
    confirm_psw: ''
  };

  const togglePassword = () => {
    setNewPswShow(!newPswShow);
  }
  const togglePassword1 = () => {
    setconfirmPswShow(!confirmPswShow);
  }
  const togglePassword3 = () => {
    setCurrentPsw(!currentPsw);
  }

  const adminPasswordSchema = Yup.object({
    current_psw: Yup.string().required('Current Password is required'),
    new_psw: Yup.string().required('Password is required'),
    confirm_psw: Yup.string()
      .oneOf([Yup.ref('new_psw'), null], 'Passwords must match')

  })



  const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
    initialValues: initialValues,
    validationSchema: adminPasswordSchema,
    enableReinitialize: true,

    onSubmit: (values, action) => {
      setLoading(true)
      dispatch(changePswTodo(values)).then(() => add_res(action))
    }
  });

  const add_res = () => {
    if (changePswRes?.status) {
      toast.success(changePswRes?.message, { position: 'bottom-right' })
      setLoading(false)
      setShow(false)
    } else {
      toast.error(changePswRes?.message, { position: 'bottom-right' })
    }
  }
  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading ?
            <div className='d-flex justify-content-center  pb-5'>
              <Loader />
            </div> :


            <form onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
              <div className='mt-2 ps-3 pe-3' style={{ position: 'relative' }}>
                <label htmlFor="user_name" className="form-label form_label">Current Password</label>
                {currentPsw ? <span className="psw_icon" onClick={togglePassword3} ><BiShow size={20} style={{ color: "grey" }} /></span> : <span className="psw_icon" onClick={togglePassword3} ><BiHide size={20} style={{ color: "#9d9d9d" }} /></span>}
                <input type={currentPsw ? "text" : "password"}
                  name='current_psw'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.current_psw}
                  className='form-control form_input' />
                {errors.current_psw && touched.current_psw ? (<span className='text-danger ' style={{ fontSize: "15px" }} >{errors.current_psw}</span>) : null}
              </div>
              <div className='mt-2 ps-3 pe-3' style={{ position: 'relative' }}>
                <label htmlFor="user_name" className="form-label form_label">New Password</label>
                {newPswShow ? <span className="psw_icon" onClick={togglePassword} ><BiShow size={20} style={{ color: "grey" }} /></span> : <span className="psw_icon" onClick={togglePassword} ><BiHide size={20} style={{ color: "#9d9d9d" }} /></span>}
                <input type={newPswShow ? "text" : "password"}
                  name='new_psw'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.new_psw}
                  className='form-control form_input' />
                {errors.new_psw && touched.new_psw ? (<span className='text-danger ' style={{ fontSize: "15px" }} >{errors.new_psw}</span>) : null}
              </div>

              <div className='mt-2 mb-4 ps-3 pe-3' style={{ position: 'relative' }}>
                <label htmlFor="user_name" className="form-label form_label">Conform Password</label>
                {confirmPswShow ?
                  <span className="psw_icon" onClick={togglePassword1} > <BiShow size={20} style={{ color: "grey" }} /></span> :
                  <span className="psw_icon" onClick={togglePassword1} ><BiHide size={20} style={{ color: "#9d9d9d" }} /></span>}
                <input
                  type={confirmPswShow ? "text" : "password"}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.confirm_psw}
                  name='confirm_psw'
                  className='form-control form_input' />

                {errors.confirm_psw && touched.confirm_psw ? (<span className='text-danger ' style={{ fontSize: "15px" }} >{errors.confirm_psw}</span>) : null}
              </div>
              <div className='col-md-12 text-end mt-2'>
                <SaveButton name={'Update'} />
              </div>
            </form>
          }
        </Modal.Body>
      </Modal>
      <ToastContainer />
    </>
  )
}
